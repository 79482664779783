import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a4677904"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "base" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainAbout = _resolveComponent("MainAbout")!
  const _component_SocialsIntegration = _resolveComponent("SocialsIntegration")!
  const _component_MainGame = _resolveComponent("MainGame")!
  const _component_EmailSignup = _resolveComponent("EmailSignup")!
  const _component_ContactUs = _resolveComponent("ContactUs")!
  const _component_CookiesBanner = _resolveComponent("CookiesBanner")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MainHeader),
    _createVNode(_component_MainAbout),
    _createVNode(_component_SocialsIntegration),
    _createVNode(_component_MainGame),
    _createVNode(_component_EmailSignup),
    _createVNode(_component_ContactUs),
    _createVNode(_component_CookiesBanner),
    _createVNode(_component_MainFooter)
  ]))
}