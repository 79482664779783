
import { defineComponent } from "vue";

// Import components
import MainHeader from "@/components/Main/MainHeader.vue";
import MainAbout from "@/components/Main/MainAbout.vue";
import SocialsIntegration from "@/components/Main/SocialsIntegration.vue";
import MainGame from "@/components/Main/MainGame.vue";
import EmailSignup from "@/components/Main/EmailSignup.vue";
import ContactUs from "@/components/Main/ContactUs.vue";
import CookiesBanner from "@/components/Main/CookiesBanner.vue";
import MainFooter from "@/components/Main/MainFooter.vue";
// import MainKickstarter from "@/components/Main/MainKickstarter.vue";

export default defineComponent({
  name: "MainView",
  components: {
    MainHeader,
    MainAbout,
    SocialsIntegration,
    EmailSignup,
    CookiesBanner,
    MainFooter,
    MainGame,
    ContactUs,
    // MainKickstarter,
  },
});
